<template>
  <section>
    <pm-Breadcrumb :home="home" :model="itemsBread" />
    <div class="col-md-12 alinhamento">
      <div class="row">
        <div class="col-md-8">
          <h2 class="titulo-links-escolha">Conteúdos Aplicados</h2>
        </div>
      </div>

      <painelFiltro
        modelo="0"
        individual="0"
        :objeto="false"
        @buscarRelatorio="buscarRelatorio"
        :filtro="filtro"
      />

      <section v-if="info.turma_id">
        <div class="grid card-filtro alinhameneto-margem">
          <div class="col-12 sm:col-12 md:col-3 lg:col-2 xl:col-2">
            <label>Escolha o mês:</label>

            <select
              class="p-inputtext p-component select"
              v-model="selectedMes"
              @change="conteudosAplicados = []"
            >
              <option
                v-for="a in mesAll"
                :key="a.id"
                :value="{ id: a.id, mes: a.mes }"
              >
                {{ a.mes }}
              </option>
            </select>
          </div>

          <div class="col-12 sm:col-12 md:col-3 lg:col-2 xl:col-2">
            <label>Escolha a disciplina:</label>
            <select
              class="p-inputtext p-component select"
              v-model="selectedDisciplina"
              @change="conteudosAplicados = []"
            >
              <option
                v-for="a in disciplinaALL"
                :key="a.id"
                :value="a"
                :selected="selectedDisciplina"
              >
                {{ a.nome }}
              </option>
            </select>
          </div>

          <div class="grid align-items-end ml-1 mb-2 pl-1">
            <button
              class="btn btn-primary pt-1 pb-1"
              @click="getConteudoAplicado()"
            >
              Pesquisar
            </button>
          </div>
        </div>

        <button
          class="btn btn-danger button-generate"
          @click="pdf()"
          title="Gerar Impressão"
          v-if="conteudosAplicados.length > 0"
        >
          <i class="fa fa-file-pdf-o"></i> Pdf
        </button>

        <button v-if="conteudosAplicados.length > 0" class="btn btn-success" @click="gerarExcel()" style="margin-top:10px;"
          title="Gerar Excel">
          <i class="fa fa-file-excel-o"></i> Excel
        </button>

        <button class="btn btn-secondary ml-1" @click="gerarCsv('dadosrel')" style="margin-top:10px;"
          v-if="conteudosAplicados.length > 0"
          title="Gerar csv">
          <i class="fa fa-file-o"></i> CSV
        </button>

        <div
          class="mt-3 row"
          id="dadosrel"
          v-if="conteudosAplicados.length > 0"
        >
          <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
            <meta charset="utf-8">
          </head>
          <br>
          <div class="mb-3">
            <label  class="col-12 flex justify-content-center m-0 header">Contéudos Aplicados - {{selectedMes.mes}} - {{ infoTurma.turma.ano }}</label>
            <h5  class="col-12 flex justify-content-center m-0 header"> {{ infoTurma.escola.nome }}</h5>
            <label  class="col-12 flex justify-content-center m-0 header"> {{ infoTurma.segmento.nome }} - {{ infoTurma.serie.nome }} - {{ infoTurma.turma.nome }} - {{ selectedDisciplina.nome }}</label>
          </div>

          <table class="table" v-if="!this.todosMeses" border="1">
            <tbody>
              <tr class="cor-titulo uppercase" align="center">
                <th class="tamanho-titulos-dias border">Dia</th>
                <th class="border" style="text-align: left;">
                  Conteúdo aplicado
                </th>
              </tr>
              <tr v-for="(item, index) in conteudosAplicados" :key="index">
                <td class="border" style="text-align: center;">{{ item.dia }}</td>
                <td class="border">{{ item.texto }}</td>
              </tr>
            </tbody>
          </table>


          <div v-else v-for="(cont, ind) in conteudosAplicados[0]" :key="ind">

            <div v-if="cont.length>0">
              <h5 style="text-align: center;margin-top:30px;margin-bottom:20px;">{{mesAll[ind]["mes"]}}</h5>
            </div>

            <table class="table" border="1">
              <tbody v-if="cont.length>0">
                <tr class="cor-titulo uppercase" align="center">
                  <th class="tamanho-titulos-dias border">Dia</th>
                  <th class="border">
                    Conteúdo aplicado
                  </th>
                </tr>
                <tr  v-for="(item, index) in cont" :key="index">
                  <td class="border" style="text-align: center;">{{ item.dia }}</td>
                  <td class="border">{{ item.texto }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>

    <div class="pm-card">
      <div class="p-fluid formgrid grid"></div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import painelFiltro from "./components/filtros/painelFiltro.vue";
import { DisciplinaEscolar } from "@/class/disciplina";
import { ConteudoAplicado } from "@/pages/admin/pages/relatorios/services/conteudoAplicado.service";

export default defineComponent({
  components: { painelFiltro },
  data() {
    return {
      mesAll: [
        {
          id: 0,
          mes: "Todos os Meses",
        },
        {
          id: 1,
          mes: "Janeiro",
        },
        {
          id: 2,
          mes: "Fevereiro",
        },
        {
          id: 3,
          mes: "Março",
        },
        {
          id: 4,
          mes: "Abril",
        },
        {
          id: 5,
          mes: "Maio",
        },
        {
          id: 6,
          mes: "Junho",
        },
        {
          id: 7,
          mes: "Julho",
        },
        {
          id: 8,
          mes: "Agosto",
        },
        {
          id: 9,
          mes: "Setembro",
        },
        {
          id: 10,
          mes: "Outubro",
        },
        {
          id: 11,
          mes: "Novembro",
        },
        {
          id: 12,
          mes: "Dezembro",
        },
      ],
      baseUrl: axios.defaults.baseURL.replace("/api/v1", ""),
      home: { icon: "pi pi-home", to: "/relatorios/conteudos-aplicados" },
      itemsBread: [{ label: "Conteúdos Aplicados" }],
      selectedMes: { id: 0, text: "" },
      selectedDisciplina: 0,
      disciplinaALL: [],
      info: {},
      conteudosAplicados: [],
      prevRoute: null,
      infoTurma: [],
      todosMeses:0
    };
  },
  methods: {
    gerarCsv(id) {
      // Obtém a div pelo id
      const div = document.getElementById(id);
      if (!div) {
        console.error('Div não encontrada: ' + id);
        return;
      }

      // Obtém todas as linhas da div
      const linhas = div.querySelectorAll('tr');

      // Cria o conteúdo do CSV
      let csv = '';
      linhas.forEach((linha) => {
        const celulas = linha.querySelectorAll('td');
        const valores = Array.from(celulas).map((celula) => celula.textContent);
        csv += valores.join(',');
        csv += '\n';
      });

      // Cria um Blob com o conteúdo do CSV
      const csvString = "\uFEFF" + csv;
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      // Cria uma URL para o Blob
      const url = URL.createObjectURL(blob);

      // Cria um link para download do arquivo CSV
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'conteudoAplicado.csv');

      // Adiciona o link ao corpo do documento e inicia o download
      document.body.appendChild(link);
      link.click();

      // Remove o link do corpo do documento
      document.body.removeChild(link);
    },
    gerarExcel(){
      const div = document.getElementById('dadosrel');
      const divp = document.getElementById("printme");

      if (div != null) {
        divp.innerHTML = div.innerHTML;
      }

      const a = document.createElement('a');
      const data_type = 'data:application/vnd.ms-excel';
      const table_html = divp.outerHTML.replace(/ /g, '%20');

      a.href = data_type + ', ' + table_html;
      a.download = 'conteudoaplicado.xls';
      a.click();
    },
    pdf() {
      const div = document.getElementById("dadosrel");
      const divp = document.getElementById("printme");
      if (div != null) {
        divp.innerHTML = div.innerHTML;
      }

      window.print();
    },

    async getConteudoAplicado() {
      this.info.disciplinaId = this.selectedDisciplina.id;
      this.info.mes = this.selectedMes.id;
      let data
      try {
        data = await ConteudoAplicado.getConteudoAplicado(
          this.info.ano,
          this.info.turma_id,
          this.info.disciplinaId,
          this.info.mes
        );
        if (data.status === 200) {
          this.conteudosAplicados = data.data.coteudos_aplicados;
          this.infoTurma = data.data.info_turma;
        } else if (data.status === 204) {
          this.conteudosAplicados = [];
          this.$vaToast.init({
            message: "Nenhum conteúdo encontrado",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            fullWidth: false,
            color: "warning",
          });
        }
      } catch (error) {
        console.log(error);
      }

      this.todosMeses = data.data.todos_meses;
      if(data.data.todos_meses === 0){
        this.conteudosAplicados.sort((a, b) => a.dia - b.dia);
      }
      else{
        for (let i = 1; i < this.conteudosAplicados[0].length; i++) {
          this.conteudosAplicados[0][i].sort((a, b) => a.dia - b.dia);
        }
      }
    },

    async buscarRelatorio(info) {
      this.info = info;
      await this.getDisciplinas();
      await this.getConteudoAplicado();
    },

    async getDisciplinas() {
      try {
        const data = await DisciplinaEscolar.obtemTodosSomenteTrue2(
          this.info.turma_id
        );
        this.disciplinaALL = data.data.disciplinas_turma;
        this.selectedDisciplina = this.disciplinaALL[0];
      } catch (error) {
        this.$vaToast.init({
          message: "Ocorreu um erro ao pesquisar as disciplinas",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3500,
          fullWidth: false,
          color: "danger",
        });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
      const divAta = document.getElementById("print");

      if ((vm.prevRoute.name === "atas" || vm.prevRoute.name === "ataAlunos") && divAta) {
        location.reload();
      }
    })
  },
  beforeMount() {
    document.getElementById('printme').innerHTML = '';
    const mesAtual = new Date().getMonth() + 1;

    this.selectedMes = this.mesAll.find(({ id }) => id === mesAtual);
  },
});
</script>

<style scoped>
.button-generate {
  margin-top: 10px;
  margin-right: 5px;
}
.select {
  appearance: revert;
  width: 100%;
}
.cor-titulo {
  background-color: #292828 !important;
  color: #fff;
}

.uppercase {
  text-transform: uppercase;
}
.tamanho-titulos-dias {
  width: 3%;
}
.alinhamento {
  margin-top: -15px;
  padding: 14px;
}

.alinhameneto-margem {
  margin-left: -12px;
  margin-right: -12px;
}

.card-filtro {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  margin-top: 15px;
}
</style>

<style>


.border{
      border: 1px solid black;
}

.header{
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}

#noprint {
  display: block;
}
#printme {
  display: none;
}

@media print {
  #noprint {
    display: none;
  }
  #printme {
    display: block;
  }
}
</style>
